import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import velvet from '../../images/velvet.png'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const VelvetPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Velvet</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={velvet} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                "It is admirable to find a recital album for an unusual solo
                instrument - in this case, the tuba - that includes as much
                original music as this one…Velvet Brown has lots of technique
                and is particularly good in the expressive moments in all of the
                pieces..." --Fanfare, Nov/Dec 1999
              </p>
              <p className='my-4'>
                "This disc is both significant and welcome, since it surely must
                be the first recording of classical tuba solos by a black woman,
                and since it is very good…She is a very expressive player whose
                tone is focused yet robust and lively in all registers."
                --American Record Guide, Nov/Dec 1999
              </p>
              <p className='my-4'>
                "This recording includes worthwhile literature that is very well
                conveyed by Velvet Brown. More than just low brass players will
                enjoy listening to her playing on this CD." --ITA Journal,
                Summer 2000
              </p>
              <p className='my-4'>
                <a
                  href='https://www.amazon.com/Velvet-Brown/dp/B00000JQJG'
                  target='_blank'
                  rel='noreferrer'
                >
                  "Velvet" can be purchased here
                </a>
              </p>
            </div>
            <div className='soundcloudWrapper'>
              <iframe
                width='100%'
                height='400'
                scrolling='no'
                frameborder='no'
                allow='autoplay'
                src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/3200987&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=false'
              ></iframe>
              <div class='font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,  Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;' />
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={velvet} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              <iframe
                width='100%'
                height='200'
                scrolling='no'
                frameborder='no'
                allow='autoplay'
                src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/3200987&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=false'
              ></iframe>
              <div class='font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,  Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;' />
            </p>
            <p className='my-4'>
              "It is admirable to find a recital album for an unusual solo
              instrument - in this case, the tuba - that includes as much
              original music as this one…Velvet Brown has lots of technique and
              is particularly good in the expressive moments in all of the
              pieces..." --Fanfare, Nov/Dec 1999
            </p>
            <p className='my-4'>
              "This disc is both significant and welcome, since it surely must
              be the first recording of classical tuba solos by a black woman,
              and since it is very good…She is a very expressive player whose
              tone is focused yet robust and lively in all registers."
              --American Record Guide, Nov/Dec 1999
            </p>
            <p className='my-4'>
              "This recording includes worthwhile literature that is very well
              conveyed by Velvet Brown. More than just low brass players will
              enjoy listening to her playing on this CD." --ITA Journal, Summer
              2000
            </p>
            <p className='my-4'>
              <a
                href='https://www.amazon.com/Velvet-Brown/dp/B00000JQJG'
                target='_blank'
                rel='noreferrer'
              >
                "Velvet" can be purchased here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default VelvetPage
